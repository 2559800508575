<template>
<div class="register flex w-full no-gutter justify-center" id="page-login">
    <div v-bind:class="[ _mobile  ? 'mobile' : 'pc' ]">

        <a-row style="width:100%;margin-top:140px;margin-bottom:100px">
            <a-col :span="span" :offset="offSet" style="text-align:center">
                <img style="width:113px" src="https://firebasestorage.googleapis.com/v0/b/store-link-49fa1.appspot.com/o/storelink3%2Fdist%2Fimg%2Flogo-blue%402x.png?alt=media&token=9c574e13-0fa9-46fb-8c40-bd2fbbf349d1" />
            </a-col>
            <a-col :span="24" class="title mobile" v-if="_mobile">
                온라인스토어,<br/> <span style="color:#0264fb;font-weight:bold">상위노출</span>을 <span style="color:#0264fb;font-weight:bold">링크</span>하다.
            </a-col>
            <a-col :span="24" class="subtitle mobile" v-if="_mobile">
                스토어링크의 순위관리 서비스를 <br />7일 동안 무료로 체험하세요.<br /> 상위노출 마케팅이 궁금하신 분은<br /> 서비스 문의를 남겨주세요.
            </a-col>
            <a-col :span="24" class="title" v-if="!_mobile">
                온라인스토어, <span style="color:#0264fb;font-weight:bold">상위노출</span>을 <span style="color:#0264fb;font-weight:bold">링크</span>하다.
            </a-col>
            <a-col :span="24" class="subtitle" v-if="!_mobile">
                스토어링크의 순위관리 서비스를 7일 동안 무료로 체험하세요.<br />
                상위노출 마케팅이 궁금하신 분은 서비스 문의를 남겨주세요.
            </a-col>

            <a-col class="m-div" :span="span" :offset="offSet">
                <div class="r-input" style="border-bottom:1px solid #dbdbdb">
                    <a-input placeholder="이메일" v-model="email" tyle="email">
                        <a-icon slot="prefix" type="mail" />
                    </a-input>
                </div>
                <div class="r-input" style="border-bottom:1px solid #dbdbdb">
                    <a-input placeholder="비밀번호" v-model="password" type="password">
                        <a-icon slot="prefix" type="lock" />
                    </a-input>
                </div>
                <div class="r-input" style="border-bottom:1px solid #dbdbdb">
                    <a-input placeholder="비밀번호확인" v-model="confirm_password" type="password">
                        <a-icon slot="prefix" type="lock" />
                    </a-input>
                </div>
                <div class="r-input" style="border-bottom:1px solid #dbdbdb">
                    <a-input placeholder="이름" v-model="displayName" type="text">
                        <a-icon slot="prefix" type="idcard" />
                    </a-input>
                </div>
                <div class="r-input" style="border-bottom:1px solid #dbdbdb">
                    <a-input placeholder="기업명 혹은 소속기관" v-model="companyName" type="text">
                        <a-icon slot="prefix" type="shop" />
                    </a-input>
                </div>
                <div class="r-input" style="border-bottom:1px solid #dbdbdb">
                    <a-input placeholder="휴대폰 번호" v-model="phone" type="phone" v-mask="['###-###-####','###-####-####']">
                        <a-icon slot="prefix" type="phone" />
                    </a-input>
                </div>
                <div class="r-input-tag">
                    <div class="text"><i class="fal fa-location-arrow"></i><span style="padding-left:5px;color:#bfbfbf">유입경로를 선택해주세요</span></div>
                    <div style="padding:10px">
                        <a-radio-group @change="handleChange">
                            <a-radio style="display:block;height: 30px;lineHeight:30px;margin-top:5px;margin-bottom:5px" :value="item.key" v-for="item in inputtypes" :key="item.key">
                                {{item.value}}
                                <a-input v-model="flowex" v-if="item.key=='기타'" :disabled="flow!='기타'" style="width:100%;margin-left:2px" />
                            </a-radio>

                        </a-radio-group>
                    </div>
                </div>
            </a-col>
            <a-col :span="span" :offset="offSet" style="margin-top:15px">
                <a-checkbox @change="onChange">
                    <a href="https://storelink.io/doc/storelink_termsofuse.html" target="_blank"><u>이용약관</u></a> 및 <a href="https://storelink.io/doc/storelink_privacypolicy.html" target="_blank"><u>개인정보 처리방침</u></a> 에 동의 합니다.
                </a-checkbox>
            </a-col>

            <a-col :span="span" :offset="offSet" style="margin-top:15px">
                <div class="s-standard-button-primary" v-if="agree">
                    <a-button @click="registerUser()">
                        회원가입
                    </a-button>
                </div>
                <div class="s-standard-button" v-else>
                    <a-button :disabled="true">
                        회원가입
                    </a-button>
                </div>
            </a-col>

        </a-row>
    </div>
</div>
</template>

<script>
import { isMobile } from "../../components/fnc.js";
export default {
  data() {
    return {
      agree: false,
      email: "",
      password: "",
      confirm_password: "",
      displayName: "",
      phone: "",
      companyName: "",
      span: 6,
      offSet: 9,
      flow: "",
      flowex: "",
      inputtypes: [
        {
          key: "인터넷검색",
          value: "인터넷검색"
        },
        {
          key: "SNS광고",
          value: "SNS광고"
        },
        {
          key: "지인추천",
          value: "지인추천"
        },
        {
          key: "이메일제안서",
          value: "이메일제안서"
        },
        {
          key: "전화제안",
          value: "전화제안"
        },
        {
          key: "기타",
          value: "기타"
        }
      ]
    };
  },
  computed: {
    validateForm() {
      return !this.errors.any() && this.email !== "" && this.password !== "";
    },
    _mobile: function() {
      var _mobile = this.$vssWidth < 1024 ? true : isMobile();
      this.span = _mobile ? 24 : 8;
      this.offSet = _mobile ? 0 : 8;
      return _mobile;
    }
  },
  methods: {
    handleChange(e) {
      this.flow = e.target.value;
    },
    onChange(e) {
      this.agree = e.target.checked;
    },
    registerUser() {
      if (
        this.email != "" &&
        this.password != "" &&
        this.confirm_password != "" &&
        this.displayName != "" &&
        this.companyName != "" &&
        this.phone != "" &&
        ((this.flow != "" && this.flow != "기타") ||
          (this.flow == "기타" && this.flowex != ""))
      ) {
        var _flow =
          this.flow == "기타" ? "기타[" + this.flowex + "]" : this.flow;
        const payload = {
          userDetails: {
            displayName: this.displayName.trim(),
            companyName: this.companyName.trim(),
            phone: this.phone,
            email: this.email.trim(),
            password: this.password,
            confirmPassword: this.confirm_password,
            flow: _flow
          },
          notify: this.$vs.notify
        };

        this.$store.dispatch("auth/registerUser", payload);
      } else {
        this.$vs.notify({
          title: "필수입력 누락",
          text: "모든 값을 입력해주세요",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger"
        });
      }
    }
  },
  mounted() {
    document.getElementById("fullroot").scrollTop = 0;
  }
};
</script>

<style>
.r-input input {
  height: 59px;
  border-left: 0px solid white;
  border-right: 0px solid white;
  border-top: 0px solid white;
  border-bottom: 0px solid white;
  border-radius: 0%;
  outline: none !important;
  -webkit-appearance: none;
}

.r-input-tag {
  height: 269px;
  padding-top: 10px;
  padding-left: 5px;
  background-color: white;
}

.r-input-tag.mobile {
  height: 105px;
}

.r-input-tag .text {
  padding-left: 7px;
}

.r-input-tag .ant-tag {
  cursor: pointer;
  height: 30px;
  padding-top: 5px;
  margin: 3px;
}

.r-input i,
.r-input-tag i {
  color: #0264fb;
}

.r-input input:focus,
.r-input input:hover {
  border-left: 0px solid white !important;
  border-right: 0px solid white !important;
  border-top: 0px solid white !important;
  border-bottom: 0px solid white !important;
  outline-color: transparent !important;
  outline-style: none !important;
  outline: none !important;
  box-shadow: none !important;
}

.m-div {
  padding: 5px;
  border-radius: 5px;
  border: solid 1px #dbdbdb;
}
.register .title {
  text-align: center;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 36px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1.5px;
  color: #0a367b;
  margin-top: 20px;
  margin-bottom: 20px;
}

.register .title.mobile {
  text-align: center;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 24px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -1px;
  text-align: center;
  color: #0a367b;
  width: 100%;
}

.register .subtitle {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: -0.5px;
  text-align: center;
  color: #444444;
  margin-bottom: 20px;
}

.register .subtitle.mobile {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: -0.44px;
  text-align: center;
  color: #444444;
  width: 100%;
}

#page-login > .mobile {
  width: 90%;
  margin: 0 auto;
}

#page-login > .pc {
  width: 100%;
}
</style>
